import React from "react";
import Form from "react-bootstrap/Form";
import CurrencyInput from "react-currency-input-field";
import FormCol from "../../../FormCol";
import SIFAuthButton from '../../../SIFAuthButton';

function Financial({
    account,
    handleFinancialChange,
    handleChange,
    updateAccountFieldsAndActivities,
    readOnly,
    isClaimCreator,
    aYearFromNow,
}) {
  return (
    <>
      <FormCol>
        <Form.Label>Amount Due</Form.Label>
        <CurrencyInput
          prefix="$"
          name="amountDue"
          value={
            account.financial?.amountDue ? account.financial?.amountDue : 0
          }
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Principle</Form.Label>
        <CurrencyInput
          prefix="$"
          name="principle"
          value={
            account.financial?.principle ? account.financial?.principle : 0
          }
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Collection Rate</Form.Label>
        <input
          disabled={!isClaimCreator}
          value={account.account?.collectionRate}
          onChange={(e) => handleChange("collectionRate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Principle Adjustments</Form.Label>
        <CurrencyInput
          prefix="$"
          name="principleAdjustments"
          value={
            account.financial?.principleAdjustments
              ? account.financial?.principleAdjustments
              : 0
          }
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Deductible</Form.Label>
        <CurrencyInput
          prefix="$"
          name="deductible"
          value={account.account.deductible ? account.account.deductible : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Total Paid</Form.Label>
        <CurrencyInput
          prefix="$"
          name="amountPaid"
          value={
            account.financial.amountPaid ? account.financial.amountPaid : 0
          }
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>OOP</Form.Label>
        <CurrencyInput
          prefix="$"
          name="outofPocket"
          value={
            account.financial.outofPocket ? account.financial.outofPocket : 0
          }
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>
          {account.account?.projectID?.toLowerCase() === "contribution uim"
            ? "Offer"
            : "Salvage"}
        </Form.Label>
        <CurrencyInput
          prefix="$"
          name="salvage"
          value={account.account.salvage ? account.account.salvage : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Tow</Form.Label>
        <CurrencyInput
          prefix="$"
          name="towCharge"
          value={account.account.towCharge ? account.account.towCharge : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Rental</Form.Label>
        <CurrencyInput
          prefix="$"
          name="rental"
          value={account.account.rental ? account.account.rental : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
          disabled={!isClaimCreator}
        />
      </FormCol>
      <FormCol>
        <Form.Label>Collision Amount</Form.Label>
        <CurrencyInput
          prefix="$"
          name="collision"
          value={account.account.collision ? account.account.collision : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>UMBI Amount</Form.Label>
        <CurrencyInput
          prefix="$"
          name="umbi"
          value={account.account.umbi ? account.account.umbi : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>PTP Amount</Form.Label>
        <CurrencyInput
          prefix="$"
          name="ptpAmt"
          value={account.account.ptpAmt ? account.account.ptpAmt : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>PTP Date</Form.Label>
        <Form.Control
          disabled={readOnly}
          value={account.account?.ptpDate?.split("T")[0]}
          max={aYearFromNow}
          type="date"
          onChange={(e) => handleChange("ptpDate", e.target.value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>SIF Amount</Form.Label>

        <CurrencyInput
          prefix="$"
          name="prinBal"
          value={account.account.prinBal ? account.account.prinBal : 0}
          decimalsLimit={2}
          onValueChange={(value, name) => handleFinancialChange(name, value)}
        />
      </FormCol>
      <FormCol>
        <Form.Label>SIF Auth</Form.Label>
        <SIFAuthButton
          sifauth={account.account.sifauth}
          debtorID={account.account.debtorID}
          updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
        />
      </FormCol>
      <FormCol>
        <Form.Label>SIF%</Form.Label>
        <input
          disabled={!isClaimCreator}
          value={
            account.account.prinBal
              ? `${Math.round(
                  (account.account.prinBal * 100) / account.financial.principle
                )}%`
              : "0.00%"
          }
          onChange={(e) => handleChange("prinBal", e.target.value)}
        />
      </FormCol>
    </>
  );
}

export default Financial;
