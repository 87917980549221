import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FormCol from "../../../FormCol";
import FormRow from "../../../FormRow";
import StatesDropdown from "./StatesDropdown";
import CollectorDropdown from "../../../CollectorDropdown";
import ReactSelect from "react-select";
import Spinner from "react-bootstrap/Spinner";
import APIUpdatingCheckbox from "../../../APIUpdatingCheckbox";
import NCDDropdown from "../../../NCDDropdown";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import useFetch from "../../../../Utilities/useFetch";

function General({
  account,
  setAccount,
  handleChange,
  handleFinancialChange,
  updateAccountFieldsAndActivities,
  isClaimCreator,
  projectOptions,
  loadingProjects,
  setStatusChangeIsOpen,
  readOnly,
}) {
  const [ROReadOnly] = useState(false);
  let formatting_options = {
    style: "currency",
    currency: "USD",
  };
  let dollarString = new Intl.NumberFormat("en-US", formatting_options);
  const today = new Date().toISOString().split("T")[0];

  const fiveYearsFromNow = new Date(
    new Date().setFullYear(new Date().getFullYear() + 5)
  )
    .toISOString()
    .split("T")[0];

  const { data: isCollectorAdmin } = useFetch(
    true,
    `${process.env.REACT_APP_API_URL}/Claims/IsCollectorAdmin`,
    "GET"
  );
  

  return (
    <>
      <FormRow className="dark-background">
        <FormCol>
          <Form.Label>Claimant Last</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.claimantName1Last}
            onChange={(e) => handleChange("claimantName1Last", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Claimant First</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.claimantName1First}
            onChange={(e) => handleChange("claimantName1First", e.target.value)}
            className="input-small"
          />
        </FormCol>
        <FormCol>
          <Form.Label>Addr</Form.Label>
          <Form.Control
            disabled={readOnly}
            value={account.account?.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>City</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.city}
            onChange={(e) => handleChange("city", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>State</Form.Label>
          <StatesDropdown
            className="input-small full-width black-text"
            value={account.account?.state}
            onChange={(e) => handleChange("state", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Zip</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.zip}
            onChange={(e) => handleChange("zip", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>DOB</Form.Label>
          <input
            className={account.account.age < 18 ? "red-background" : ""}
            disabled={readOnly}
            type="date"
            value={account.account?.dateOfBirth?.split("T")[0]}
            onChange={(e) => handleChange("dateOfBirth", e.target.value)}
            max={today}
          />
        </FormCol>
        <FormCol>
          <Form.Label>% Liab</Form.Label>
          <input
            className={
              account.account.percentOfLiability === 100
                ? "green-background"
                : ""
            }
            disabled={readOnly}
            value={account.account?.percentOfLiability}
            onChange={(e) => handleChange("percentOfLiability", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>DL#</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.driversLicense}
            onChange={(e) => handleChange("driversLicense", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Collector</Form.Label>
          {isCollectorAdmin || isClaimCreator ? (
            <CollectorDropdown
              className="payment-form-input black-text"
              collector={account.account.collector}
              readOnly={readOnly}
              debtorID={account.account.debtorID}
              updateAccountFieldsAndActivities={
                updateAccountFieldsAndActivities
              }
            />
          ) : (
            <input
              disabled={true}
              value={account.account?.collector}
              onChange={(e) => handleChange("collector", e.target.value)}
            />
          )}
        </FormCol>
      </FormRow>

      <FormRow className="light-grey-background">
        <FormCol>
          <Form.Label>RO (last)</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.claimantRONameLast}
            onChange={(e) => handleChange("claimantRONameLast", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>(f)</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.claimantRONameFirst}
            onChange={(e) =>
              handleChange("claimantRONameFirst", e.target.value)
            }
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO DOB</Form.Label>
          <input
            type="date"
            disabled={ROReadOnly}
            value={account.account?.roDateOfBirth?.split("T")[0]}
            onChange={(e) => handleChange("roDateOfBirth", e.target.value)}
            max={today}
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO Address</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.roAddress}
            onChange={(e) => handleChange("roAddress", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO City</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.roCity}
            onChange={(e) => handleChange("roCity", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO State</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.roState}
            onChange={(e) => handleChange("roState", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO Zip</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.rozip}
            onChange={(e) => handleChange("rozip", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Plate#</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.plateNo}
            onChange={(e) => handleChange("plateNo", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Make</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.vehicleMake}
            onChange={(e) => handleChange("vehicleMake", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>YR</Form.Label>
          <input
            disabled={ROReadOnly}
            value={account.account?.yr}
            onChange={(e) => handleChange("yr", e.target.value)}
          />
        </FormCol>
      </FormRow>

      <FormRow className="black-outline">
        <FormCol>
          <Form.Label>Principle</Form.Label>
          <input
            disabled={!isClaimCreator}
            value={
              account.financial?.principle
                ? dollarString.format(account.financial.principle)
                : 0
            }
            onChange={(e) => handleFinancialChange("principle", e.target.value)}
          />
        </FormCol>
        <FormCol className="full-width">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            disabled={readOnly}
            value={account.account?.comments}
            onChange={(e) => handleChange("comments", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Project ID</Form.Label>
          {/* should be a dropdown and utilize GetProjects() */}
          {loadingProjects ? (
            <Spinner animation="border" role="status" />
          ) : projectOptions ? (
            <ReactSelect
              className="full-width"
              value={
                account.account?.projectID
                  ? projectOptions.find(
                      (project) => project.value === account.account?.projectID
                    )
                  : undefined
              }
              onChange={(e) => handleChange("projectID", e.value)}
              options={projectOptions}
              isDisabled={!isClaimCreator}
            />
          ) : null}
        </FormCol>
        <FormCol>
          <Form.Label>NCD</Form.Label>
          <NCDDropdown
            defaultDate={account.account?.nextContactDate}
            onChange={(e) => handleChange("nextContactDate", e.target.value)}
            debtorid={account.account?.debtorID}
            account={account}
            setAccount={setAccount}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Status</Form.Label>
          <InputGroup>
            <Form.Control disabled value={account.account?.debtorStatus} />
            <Button
              variant="secondary"
              onClick={() => setStatusChangeIsOpen(true)}
            >
              Change Status
            </Button>
          </InputGroup>
        </FormCol>
        <FormCol>
          <Form.Label>Status Date</Form.Label>
          <Form.Control
            readOnly
            value={account.account?.statusDate?.split("T")[0]}
            max={today}
            type="date"
          />
        </FormCol>
        <FormCol className="full-width">
          <Form.Label>Loss Desc</Form.Label>
          <Form.Control
            as="textarea"
            maxLength={250}
            value={account.account?.lossDescriptions}
            onChange={(e) => handleChange("lossDescriptions", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Gov Statute Date</Form.Label>
          <Form.Control
            value={account.account?.govStatuteDate?.split("T")[0]}
            max={fiveYearsFromNow}
            type="date"
            onChange={(e) => handleChange("govStatuteDate", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Gov Statute Desc</Form.Label>
          <input
            value={account.account?.govStatuteDesc}
            onChange={(e) => handleChange("govStatuteDesc", e.target.value)}
            maxLength={500}
          />
        </FormCol>
        <FormCol>
          <Form.Label>List Date</Form.Label>
          <Form.Control
            readOnly
            value={account.account?.listDate?.split("T")[0]}
            onChange={(e) => handleChange("listDate", e.target.value)}
            max={today}
            type="date"
          />
        </FormCol>
        <FormCol>
          <Form.Label>Adj</Form.Label>
          <input
            disabled={!isClaimCreator}
            value={account.account?.insuranceAdjuster}
            onChange={(e) => handleChange("insuranceAdjuster", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>DOL</Form.Label>
          <Form.Control
            value={account.account?.serviceDate?.split("T")[0]}
            onChange={(e) => handleChange("serviceDate", e.target.value)}
            max={today}
            type="date"
          />
        </FormCol>
        <FormCol>
          <Form.Label>State Of Loss</Form.Label>
          <input
            disabled={!isClaimCreator}
            value={account.account?.stateOfLoss}
            onChange={(e) => handleChange("stateOfLoss", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>SOL BI</Form.Label>
          <input
            disabled={true}
            value={account.limits?.biDays}
            className={
              account.limits?.biDays &&
              account.limits.biDays <= 90 &&
              account.limits.biDays > 30
                ? "yellow-background"
                : account.limits.biDays <= 30
                ? "red-background"
                : ""
            }
          />
        </FormCol>
        <FormCol>
          <Form.Label>SOL PD</Form.Label>
          <input
            disabled={true}
            value={account.limits?.pdDays}
            className={
              account.limits?.pdDays &&
              account.limits.pdDays <= 90 &&
              account.limits.pdDays > 30
                ? "yellow-background"
                : account.limits.pdDays <= 30
                ? "red-background"
                : ""
            }
          />
        </FormCol>
        <FormCol>
          <Form.Label>IV</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.insuredVehicle}
            onChange={(e) => handleChange("insuredVehicle", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Ins Last</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.insuredLastName}
            onChange={(e) => handleChange("insuredLastName", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Ins First</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.insuredFirstName}
            onChange={(e) => handleChange("insuredFirstName", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Loss Loc</Form.Label>
          <input
            disabled={readOnly}
            value={account.account?.rentalBillDraft}
            onChange={(e) => handleChange("rentalBillDraft", e.target.value)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Admin Reviewed</Form.Label>
          <Form.Check
            readOnly={readOnly}
            checked={account.account?.adminReviewed}
            onChange={(e) => handleChange("adminReviewed", e.target.checked)}
          />
        </FormCol>
        <FormCol>
          <Form.Label>MR</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.mr}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostMR"
            fieldName="mr"
          />
        </FormCol>
        <FormCol>
          <Form.Label>Ins Found</Form.Label>
          <Form.Check
            readOnly
            checked={account.account?.insuredLastName ? true : false}
          />
        </FormCol>
        <FormCol>
          <Form.Label>Ext Rqstd</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.witness}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostExtRqst"
            fieldName="witness"
          />
        </FormCol>
        <FormCol>
          <Form.Label>PTY</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.prpty}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostPTY"
            fieldName="prpty"
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO PTY</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.roprpty}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostROPTY"
            fieldName="roprpty"
          />
        </FormCol>
        <FormCol>
          <Form.Label>RO MR</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.rO_MR}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostROMR"
            fieldName="rO_MR"
          />
        </FormCol>

        <FormCol>
          <Form.Label>PR</Form.Label>
          <APIUpdatingCheckbox
            readOnly={readOnly}
            checked={account.account?.policeReport}
            updateAccountFieldsAndActivities={updateAccountFieldsAndActivities}
            debtorID={account.account?.debtorID}
            APIUrl="/Claims/PostPR"
            fieldName="policeReport"
          />
        </FormCol>
      </FormRow>
    </>
  );
}

export default General;
