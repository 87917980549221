import React from "react";
import "./SavedSearchesPopup.css";
import { Modal, Button, ListGroup, Row, Col, Form } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import FuncFetch from "../../Utilities/funcFetch";

const SavedSearchesPopup = ({
  show,
  handleClose,
  savedSearches,
  setSavedSearches,
  populateSavedSearch,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { instance, accounts } = useMsal();
  let activeAccount = instance.getActiveAccount();

  const onDelete = (index) => {
    setLoading(true);
    FuncFetch(`${process.env.REACT_APP_API_URL}/Claims/DeleteSavedSearch`,{searchID:savedSearches[index].searchID}, "DELETE", null, instance, accounts)
    .then((response) => {
      console.log('Deleted!',response);
      setSavedSearches(response);
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.log('Error!',error);
    });
  };

  const onSelect = (search) => {
    populateSavedSearch(search.searchJSON);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Saved Searches</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup style={{ maxHeight: "300px", overflowY: "scroll" }}>
          {savedSearches.map((search, index) => (
            <Row key={index} className="search-select-row">
              <Col>
                <p className="search-name">{search.searchName}</p>
              </Col>
              <Col>
                <p>Created By: {search.createdUserName}</p>
                <Form.Check
                  type="switch"
                  id={`custom-switch-${index}`}
                  label="Public"
                  checked={search.isPublic}
                  disabled={!(activeAccount.username.split("@")[0] === search.createdUserName)}
                />
              </Col>
              <Col className="search-select-button-wrapper">
                {(!search.isPublic || (activeAccount.username.split("@")[0] === search.createdUserName)) ? (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => onDelete(index)}
                    disabled={loading}
                  >
                    {loading ? "Deleting..." : "Delete"}
                  </Button>
                ) : null}
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => onSelect(search)}
                >
                  Select
                </Button>
              </Col>
            </Row>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SavedSearchesPopup;
